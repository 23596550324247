.movingToolsSection {
  text-align: center;
  background-color: #f9f9f9; /* Soft background for contrast */
  padding: 12px 0; /* Add padding to space the section */
  background-color: #e0e7f1;
}

.sectionHeading {
  font-size: 28px;
  font-weight: bold;
  color: #17315c; /* Darker color for heading */
  margin-bottom: 40px;
}

.movingToolsContainer {
  width: 100%;
  overflow: hidden; /* Hide overflow to keep animation smooth */
}

.movingTools {
  display: inline-flex;
  align-items: center;
  animation: smoothScroll 25s linear infinite; /* Increased duration for slower movement */
}

@keyframes smoothScroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%); /* Smooth infinite scrolling */
  }
}

.toolItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px; /* Reduced margin to decrease spacing */
  min-width: 170px; /* Minimum width to space out larger logos */
}

.toolLogo {
  width: 150px;
  height: 130px; /* Set height, maintain aspect ratio */
  object-fit: contain; /* Prevent stretching */
  transition: transform 0.3s ease; /* Hover effect */
}

.toolItem:hover .toolLogo {
  transform: scale(1.1); /* Slight zoom on hover for interactivity */
}

/* Optional: Add a gradient background for a more distinct separation */
/* .movingToolsSection {
  background: linear-gradient(to bottom, #f9f9f9, #0866ff);
} */
